import logo from "../../styles/images/logo-trully.png";
import logoFace from "../../styles/images/facekey.png";
import image from "../../styles/images/loginImage1.png";
import costanoa from "../../styles/images/costanoa.png";
import hyper from "../../styles/images/hyper.png";
import latem from "../../styles/images/latem.png";
import lideres from "../../styles/images/lideres.png";
import deal from "../../styles/images/dealflow.png";
import descubre from "../../styles/images/descubre.png";
import sideImage from "../../styles/images/facekey-login.png";
import { Outlet, useLocation } from "react-router-dom";
import { VERSION, styles } from "../../version";
import { useContext, useEffect } from "react";
import { PUBLIC_ROUTES } from "./routes";
import { T } from "@tolgee/react";
import LangSelector from "../LangSelector";
import AuthContext from "../../contexts/AuthContext";

const PublicLayout = () => {
  const { isFacekey } = useContext(AuthContext);
  const location = useLocation();
  const signIn = location.pathname === PUBLIC_ROUTES.SIGNIN;

  useEffect(() => {
    if (window.captcha) {
      const captcha = window.captcha.parentNode;
      if (captcha) captcha.style.display = "block";
    }
  }, []);

  return (
    <>
      <main className="publicLayout">
        <div className="publicLayout__container">
          <section
            className={`${
              signIn ? "publicLayout__form--signin" : "publicLayout__form"
            }`}
          >
            <div
              style={{
                width: "65%",
                minWidth: "380px",
                paddingRight: "15px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <img
                src={isFacekey ? logoFace : logo}
                alt="logo trully"
                className="publicLayout__form--logo"
              />
              <div style={{ marginTop: "10px" }}>
                <LangSelector />
              </div>
            </div>
            <div
              className={`${
                signIn
                  ? "publicLayout__form__container--signin"
                  : "publicLayout__form__container"
              }`}
            >
              <Outlet />
            </div>
          </section>
        </div>
        {isFacekey ? (
          <section className="publicLayout__container">
            <img
              src={sideImage}
              alt="Imagen de muestra"
              className="publicLayout__container--facekey-image"
            />
          </section>
        ) : (
          <div className="publicLayout__container publicLayout__container--image">
            <section className="publicLayout__image">
              <p className="text-1 publicLayout__image--title">
                <T keyName="init_description" />
                <b>
                  <T keyName="init_description_black" />
                </b>
              </p>
              <span className="text-1">
                <T keyName="init_description_data" />
              </span>
              <img src={image} alt="Imagen de muestra" />
              <span className="text-1">
                <T keyName="init_description_publicity" />
              </span>
              <div className="publicLayout__image--images">
                <img src={costanoa} alt="logo costonoa" />
                <img src={hyper} alt="logo hyper latam" />
                <img src={latem} alt="logo latem" />
                <img src={lideres} alt="logo lideres" />
                <img src={deal} alt="logo deal" />
                <img src={descubre} alt="logo descubre" />
              </div>
            </section>
          </div>
        )}
      </main>
      <h4 style={styles}>{VERSION}</h4>
    </>
  );
};
export default PublicLayout;
