import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useContext, useRef, useState } from "react";
import Badge from "./Badge";
import { COLORS } from "../styles/utils/colors";
import SingleDescription from "./SingleDescription";
import MultipleDescriptions from "./MultipleDescriptions";
import ShowImage from "./ShowImage";
import WarningsContainer from "./WarningsContainer";
import { useTolgee } from "@tolgee/react";
import ScrollContext from "../contexts/ScrollContext";

const ReporteDataAccordion = ({
  expandPanel,
  doubleBorder,
  accordionTitle,
  description,
  showBadge,
  SubDropdown,
  warningsToShow,
  multipleDescriptions,
  onlyImage,
}) => {
  const [showImage, setShowImage] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";
  const { handleScroll } = useContext(ScrollContext);
  const containerRef = useRef(null);

  const handleChange = (panel) => (isExpanded) => {
    if (panel === "") setExpanded(false);
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = () => {
    setTimeout(() => {
      const height = containerRef.current.offsetTop;
      handleScroll(height);
    }, 100);
  };

  return (
    <div ref={containerRef}>
      <Accordion
        key={expandPanel}
        expanded={expanded === expandPanel}
        onChange={!expanded ? handleChange(expandPanel) : handleChange("")}
        className={`${
          doubleBorder
            ? "reporteDataAccordion--double-border"
            : "reporteDataAccordion"
        } ${expandPanel}`}
      >
        <AccordionSummary
          aria-controls={`${expandPanel}-content`}
          id={`${expandPanel}-header`}
          className="reporteDataAccordion__title"
          style={{
            borderBottom: !expanded ? `1px solid ${COLORS.CATSKILL_WHITE}` : "",
          }}
          onClick={handleClick}
        >
          <div className="reporteDataAccordion__title__text">
            <button>
              {!expanded ? (
                <div className="icon-down reporteDataAccordion__title__icon"></div>
              ) : (
                <div className="icon-expand-menos reporteDataAccordion__title__icon"></div>
              )}
            </button>
            <div className="reporteDataAccordion__title__container">
              <h3 className="text-1--bold">{accordionTitle}</h3>
              {showBadge && warningsToShow.length > 0 && (
                <Badge
                  icon="icon-error-relleno"
                  color={COLORS.COPPERFIELD}
                  bgColor={COLORS.PEACH_SCHNAPPS}
                  text={isLangEs ? "Advertencia" : "Warning"}
                  styles={{
                    container: {
                      width: "fit-content",
                      borderRadius: "8px",
                      minWidth: "123px",
                    },
                    icon: {
                      width: "12px",
                      height: "12px",
                      fontSize: "0.75rem",
                    },
                    text: {
                      fontFamily: "DM sans Bold",
                      fontWeight: "700",
                      fontSize: "0.875rem",
                      lineHeight: "1.125rem",
                    },
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderBottom: expanded ? `1px solid ${COLORS.CATSKILL_WHITE}` : "",
            padding: "0 16px",
          }}
          sx={{ transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;" }}
        >
          {showBadge && warningsToShow.length > 0 && (
            <WarningsContainer
              warningsToShow={warningsToShow}
              expandPanel={expandPanel}
            />
          )}
          {multipleDescriptions ? (
            <MultipleDescriptions
              multipleDescriptions={multipleDescriptions}
              SubDropdown={SubDropdown}
            />
          ) : (
            <>
              {onlyImage ? (
                <ShowImage
                  showImage={showImage}
                  setShowImage={setShowImage}
                  text={description[0].text}
                  image={description[0].image}
                />
              ) : (
                <SingleDescription
                  description={description}
                  SubDropdown={SubDropdown}
                  showImage={showImage}
                  setShowImage={setShowImage}
                />
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default ReporteDataAccordion;
