import { T, useTolgee, useTranslate } from "@tolgee/react";
import moment from "moment/moment";

const Notification = ({ notification, snack, index, handleClick }) => {
  const { t } = useTranslate();
  const {
    active,
    created_on,
    external_id,
    id,
    identity_id,
    message,
    timestamp,
    identity_reported,
  } = notification;
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "Cuenta mula":
        return "Mule Account";
      case "Suplantación de identidad":
        return "Identity fraud";
      case "Irregularidad de pago":
        return "Defaulter";
      case "Comportamiento Sospechoso":
        return "Suspect Behavior";
      case "Contracargo":
        return "Charge back";
      case "Otros":
        return "Others";
      default:
        return text;
    }
  };

  const today = moment();
  const time = today.diff(moment(created_on));
  const duration = moment.duration(time);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const clientID = external_id || identity_id;

  return (
    <div
      style={snack ? { bottom: `${(index + 1) * -105}px` } : {}}
      className={`snackBar--notification${snack ? "-snack" : ""}${
        !snack && !active ? "--seen" : ""
      }`}
    >
      <div className={`snackBar--notification__content`}>
        <div className={`snackBar--notification__title`}>
          {(active || snack) && (
            <div className={`snackBar--notification__mark`}></div>
          )}{" "}
          {snack && (
            <h4 className="text-1--bold--t2">
              <T keyName="notification_title" />
            </h4>
          )}
          {!snack && (
            <p style={{ width: "100%" }}>
              {t("notification_description_1", "El ")}
              <b>{t("notification_description_2", "cliente activo")}</b>
              {t("notification_description_3", " con ")}
              <b>
                {t("notification_description_4", "ID {clientID}", { clientID })}
              </b>
              {t("notification_description_5", " ha sido reportado por ")}
              <b>
                {t("notification_description_6", "{message}", {
                  message: translate(message),
                })}
              </b>
              {t("notification_description_7", " por otra institución")}
            </p>
          )}
        </div>
        {snack && (
          <p className={`snackBar--notification__text`}>
            {t("notification_description_1", "El ")}
            <b>{t("notification_description_2", "cliente activo")}</b>
            {t("notification_description_3", " con ")}
            <b>
              {t("notification_description_4", "ID {clientID}", { clientID })}
            </b>
            {t("notification_description_5", " ha sido reportado por ")}
            <b>
              {t("notification_description_6", "{message}", {
                message: translate(message),
              })}
            </b>
            {t("notification_description_7", " por otra institución")}
          </p>
        )}
        {!snack && (
          <>
            {days >= 1 ? (
              <p className="snackBar--notification__text text-4 color--shuttle-gray">
                {t("notification_days", "Hace {days} días", { days })}
              </p>
            ) : hours > 0 ? (
              <p className="snackBar--notification__text text-4 color--shuttle-gray">
                {t("notification_hours", "Hace {hours} horas", { hours })}
              </p>
            ) : minutes > 0 ? (
              <p className="snackBar--notification__text text-4 color--shuttle-gray">
                {t("notification_minutes", "Hace {minutes} minutos", {
                  minutes,
                })}
              </p>
            ) : (
              <p className="snackBar--notification__text text-4 color--shuttle-gray">
                {t("notification_seconds", "Hace {seconds} segundos", {
                  seconds,
                })}
              </p>
            )}
          </>
        )}
      </div>
      <button
        className={`snackBar--notification__btn`}
        onClick={() =>
          handleClick({ identity_id, id, timestamp, identity_reported })
        }
      >
        <div className="icon-siguiente"></div>
      </button>
    </div>
  );
};
export default Notification;
