import { useContext, useState } from "react";
import TextDataResumen from "./TextDataResumen";
import ReportContext from "../contexts/ReportContext";
import ReportarForm from "./ReportarForm";
import AuthContext from "../contexts/AuthContext";
import Button from "./Button";
import { COLORS } from "../styles/utils/colors";
import { useTolgee, useTranslate } from "@tolgee/react";

const ReportarFlagged = ({ flag, handleReset, setShowResumen }) => {
  const [editFlag, setEditFlag] = useState(false);
  const {
    user: { first_name, last_name },
  } = useContext(AuthContext);
  const authorName = flag.created_by || `${first_name} ${last_name}`;
  const { flagReport } = useContext(ReportContext);
  const { reason_type, request_id, wasFlagged } = flag;
  const [disableBtn, setDisableBtn] = useState(false);
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "Cuenta mula":
        return "Mule Account";
      case "Suplantación de identidad":
        return "Identity fraud";
      case "Irregularidad de pago":
        return "Defaulter";
      case "Comportamiento Sospechoso":
        return "Suspect Behavior";
      case "Contracargo":
        return "Charge back";
      case "Otros":
        return "Others";
      default:
        return text;
    }
  };

  if (editFlag) {
    return <ReportarForm flagged={wasFlagged} />;
  }

  return (
    <div className="reportarFlagged">
      <div>
        <h3 className="text-1 color--shark reportarFlagged--title">
          {t("report_flagged_title")}
        </h3>
        <TextDataResumen
          description={t("report_flagged_flagged_for")}
          value={translate(reason_type)}
          border="bottom"
        />
        <TextDataResumen
          description={t("report_flagged_request_id")}
          value={request_id}
          border="bottom"
        />
      </div>
      <p className="reportarFlagged--comment text-2">{flag.description}</p>
      <div className="reportarFlagged__author">
        <span className="text-3">{t("report_flagged_flagged_by")}</span>
        <span className="text-3">{authorName}</span>
      </div>
      {wasFlagged && (
        <Button
          text={t("btn_edit")}
          icon="icon-flag"
          handleClick={() => setEditFlag(true)}
          styles={{
            width: "fit-content",
            padding: "8px 12px",
          }}
        />
      )}
      {!wasFlagged && (
        <div className="reportarFlagged__btn-container">
          <Button
            icon="icon-flag"
            text={t("btn_send")}
            disabled={disableBtn}
            handleClick={() => {
              setDisableBtn(true);
              flagReport(flag);
              handleReset();
            }}
            styles={{
              padding: "8px 12px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          />
          <Button
            text={t("btn_edit")}
            handleClick={() => setShowResumen(false)}
            styles={{
              padding: "8px 12px",
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.SHUTTLE_GRAY}`,
              color: COLORS.SHUTTLE_GRAY,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default ReportarFlagged;
