import { useTolgee, useTranslate } from "@tolgee/react";
import { COLORS } from "../styles/utils/colors";
import Button from "./Button";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ShowImage = ({ showImage, image, text, setShowImage }) => {
  const { t } = useTranslate();
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const translate = (text) => {
    if (isLangEs) return text;

    switch (text) {
      case "Imagen del aplicante":
        return "Applicant's selfie";
      case "Documento de identificación":
        return "Applicant's ID";
      case "Reiniciar":
        return "Reset";
      case "cerrar":
        return "close";

      default:
        return text;
    }
  };

  return (
    <div className="reporteDataAccordion__image">
      {!showImage && (
        <>
          <div className="reporteDataAccordion__image__container">
            <img src={image} alt={text} />
          </div>
          <Button
            handleClick={() => setShowImage((prev) => !prev)}
            text={t("show_img_btn")}
          />
        </>
      )}
      {showImage && (
        <div className="reporteDataAccordion__modal">
          <div className="reporteDataAccordion__modal__container">
            <h5 className="heading-2">
              {text === "selfie"
                ? translate("Imagen del aplicante")
                : translate("Documento de identificación")}
            </h5>
            <div className="reporteDataAccordion__image__container--modal">
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                wheel={{
                  step: 0.5,
                  smoothStep: 0.001,
                  excluded: [],
                  activationKeys: [],
                }}
                pinch={{
                  excluded: [],
                  step: 5,
                }}
                panning={{
                  activationKeys: [],
                  excluded: [],
                }}
                doubleClick={{
                  excluded: [],
                  step: 0.7,
                }}
                onInit={function noRefCheck() {}}
                onPanning={function noRefCheck() {}}
                onPanningStart={function noRefCheck() {}}
                onPanningStop={function noRefCheck() {}}
                onPinching={function noRefCheck() {}}
                onPinchingStart={function noRefCheck() {}}
                onPinchingStop={function noRefCheck() {}}
                onTransformed={function noRefCheck() {}}
                onWheel={function noRefCheck() {}}
                onWheelStart={function noRefCheck() {}}
                onWheelStop={function noRefCheck() {}}
                onZoom={function noRefCheck() {}}
                onZoomStart={function noRefCheck() {}}
                onZoomStop={function noRefCheck() {}}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <div className="showImage__zoomContainer">
                    <div className="showImage__zoomTools">
                      <Button
                        handleClick={() => zoomIn()}
                        text="Zoom +"
                        styles={{
                          color: COLORS.SHARK,
                          backgroundColor: COLORS.WHITE,
                          border: `1px solid ${COLORS.SHARK}`,
                          borderRadius: "4px",
                          padding: "4px 8px",
                          boxShadow: "none",
                        }}
                        btnTextClass="capitalize"
                      />
                      <Button
                        handleClick={() => zoomOut()}
                        text="Zoom -"
                        styles={{
                          color: COLORS.SHARK,
                          backgroundColor: COLORS.WHITE,
                          border: `1px solid ${COLORS.SHARK}`,
                          borderRadius: "4px",
                          padding: "4px 8px",
                          boxShadow: "none",
                        }}
                        btnTextClass="capitalize"
                      />
                      <Button
                        handleClick={() => resetTransform()}
                        text={translate("Reiniciar")}
                        styles={{
                          color: COLORS.SHARK,
                          backgroundColor: COLORS.WHITE,
                          border: `1px solid ${COLORS.SHARK}`,
                          borderRadius: "4px",
                          padding: "4px 8px",
                          boxShadow: "none",
                        }}
                        btnTextClass="capitalize"
                      />
                    </div>
                    <TransformComponent>
                      <img src={image} alt={text} className="showImage__img" />
                    </TransformComponent>
                  </div>
                )}
              </TransformWrapper>
            </div>
            <Button
              handleClick={() => setShowImage((prev) => !prev)}
              text={translate("cerrar")}
              styles={{ alignSelf: "flex-end" }}
              btnTextClass="capitalize"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ShowImage;
