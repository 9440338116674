import { createContext, useMemo, useReducer } from "react";
import { generateRequest } from "../helpers/helpHttp";
import { ROLES, authReducer } from "../reducers/authReducer";
import { TYPES } from "../actions/authActions";
import { url } from "./domain";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "../components/pages/routes";
import { SESSION_MESSAGES, initialState } from "../states/contexts/AuthContext";
import { saveMetadata } from "../helpers/saveMetadata";
import { useTolgee } from "@tolgee/react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const tolgee = useTolgee(["language"]);
  const isLangEs = tolgee.getLanguage() === "es-MX";

  const navigate = useNavigate();
  const {
    user,
    token,
    loading,
    error,
    sessionExpired,
    sessionExpiredText,
    sendMailError,
    mailSended,
    newPassError,
    passChanged,
    signupError,
    isFacekey,
  } = state;

  const setSendMailError = (errText) => {
    dispatch({
      type: TYPES.SEND_MAIL_ERROR,
      payload: {
        err: true,
        errText,
      },
    });
  };

  const setNewPassError = (errText) => {
    dispatch({
      type: TYPES.NEW_PASS_ERROR,
      payload: {
        err: true,
        errText,
      },
    });
  };

  const setLogError = (errText) => {
    dispatch({
      type: TYPES.LOG_ERROR,
      payload: {
        err: true,
        errText,
      },
    });
  };

  const getNotifications = async ({ requestStartOn }) => {
    if (requestStartOn === 0)
      return await generateRequest.get({
        url: `${url}/profile/notifications?page=${requestStartOn}&offset=10`,
        invalidSession,
      });

    const { notifications, active_count } = await generateRequest.get({
      url: `${url}/profile/notifications?page=${requestStartOn}&offset=10`,
      invalidSession,
    });

    if (notifications?.length > 0) {
      const newUserState = {
        ...user,
        notifications: {
          list: [...user.notifications.list, ...notifications],
          not_seen: active_count,
        },
      };

      updateUser(newUserState);
    }
  };

  const handleAccessError = (getAccess, errorTexts) => {
    const texts = {
      login: {
        ...getAccess,
        errText:
          getAccess.response_status === 400
            ? isLangEs
              ? "Correo electrónico y/o contraseña incorrecto"
              : "Invalid email address or password"
            : isLangEs
            ? "No pudimos verificar tus credenciales. Por favor, vuelve a intentarlo más tarde"
            : "Credentials' check failed. Please, try again later.",
        failAccess: getAccess.response_status !== 400,
      },
      sso: {
        ...getAccess,
        errText: isLangEs
          ? "Correo electrónico no válido"
          : "Invalid email address",
        failAccess: getAccess.response_status !== 400,
      },
    };

    if (getAccess.err) {
      const error = texts[errorTexts];

      throw error;
    }
  };

  const fetchUserData = async () => {
    const getUserData = await generateRequest.get({
      url: `${url}/profile`,
      invalidSession,
    });

    if (getUserData.err) {
      const errorUser = {
        ...getUserData,
        errText: isLangEs
          ? "No pudimos iniciar sesión. Por favor, intenta más tarde"
          : "Login failed. Please, try again later.",
        failUserData: true,
      };

      throw errorUser;
    }

    return getUserData;
  };

  const handleLoginError = (error) => {
    if (error?.failAccess || error?.failUserData) {
      dispatch({ type: TYPES.LOG_ERROR, payload: error });
      return;
    }

    dispatch({ type: TYPES.LOG_ERROR, payload: error });
  };

  const clearPreviousLoginError = () => {
    dispatch({ type: TYPES.LOG_ERROR, payload: false });
  };

  const login = async (formToValidate, email, password, captcha) => {
    const hasInvalidFields = (formToValidate) => {
      return formToValidate.find((el) => !el.valid);
    };

    const handleInvalidFieldsError = () => {
      const err = {
        err: true,
        errText: isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data",
      };

      dispatch({ type: TYPES.LOG_ERROR, payload: err });
    };

    const createLoginRequestData = (email, password) => {
      return {
        url: `${url}/users/login`,
        options: {
          headers: { "content-type": "application/json" },
          body: { email, password, captcha },
        },
        invalidSession,
      };
    };

    if (hasInvalidFields(formToValidate)) {
      handleInvalidFieldsError();
      return;
    }

    dispatch({ type: TYPES.LOADING, loading: true });

    try {
      clearPreviousLoginError();
      const requestData = createLoginRequestData(email, password);
      const getAccess = await generateRequest.post(requestData);

      handleAccessError(getAccess, "login");

      const getUserData = await fetchUserData();
      const notifications = await getNotifications({ requestStartOn: 0 });

      saveMetadata({ userData: { getUserData, notifications } });
      sessionStorage.setItem("logged", "logged");

      dispatch({
        type: TYPES.GET_TOKEN,
        payload: {
          userData: getUserData,
          notifications,
        },
      });

      navigate(PRIVATE_ROUTES.DASHBOARD);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const ssoLogin = async (credentialResponse) => {
    const generateRequestData = (credentialResponse) => {
      return {
        url: `${url}/users/oauth`,
        options: {
          headers: {
            "content-type": "application/json",
            "x-google-key": credentialResponse.clientId,
          },
          body: { credential: credentialResponse.credential },
        },
        invalidSession,
      };
    };

    dispatch({ type: TYPES.LOADING, loading: true });

    try {
      clearPreviousLoginError();
      const requestData = generateRequestData(credentialResponse);
      const getAccess = await generateRequest.post(requestData);

      handleAccessError(getAccess, "sso");

      const getUserData = await fetchUserData();
      const notifications = await getNotifications({ requestStartOn: 0 });

      saveMetadata({ userData: { getUserData, notifications } });
      sessionStorage.setItem("logged", "logged");

      dispatch({
        type: TYPES.GET_TOKEN,
        payload: {
          userData: getUserData,
          notifications,
        },
      });

      navigate(PRIVATE_ROUTES.DASHBOARD);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const ssoLoginError = () => {
    const error = {
      errText: isLangEs
        ? "No pudimos verificar tus credenciales. Por favor, vuelve a intentarlo más tarde"
        : "Credentials' check failed. Please, try again later.",
      failAccess: true,
    };
    dispatch({ type: TYPES.LOG_ERROR, payload: error });
  };

  const keepSessionOpen = async () => {
    const handleUserDataError = (getUserData) => {
      if (getUserData.status === 502) {
        terminateSession(getUserData.message);
      } else {
        const message =
          getUserData.message === "Invalid Session"
            ? SESSION_MESSAGES.DOUBLE_SESSION_MESSAGE
            : SESSION_MESSAGES.SESSION_EXPIRED_MESSAGE;
        terminateSession(message);
      }
    };

    const saveUserSessionData = (getUserData, notifications) => {
      saveMetadata({ userData: { getUserData, notifications } });
    };

    const dispatchSessionData = (getUserData, notifications) => {
      dispatch({
        type: TYPES.GET_TOKEN,
        payload: {
          token,
          userData: getUserData,
          notifications,
        },
      });
    };

    try {
      const getUserData = await fetchUserData();
      if (getUserData.err) {
        handleUserDataError(getUserData);
        return;
      }

      const notifications = await getNotifications({ requestStartOn: 0 });
      saveUserSessionData(getUserData, notifications);

      dispatchSessionData(getUserData, notifications);
    } catch (error) {
      console.error("Error keeping session open:", error);
    }
  };

  const logout = async () => {
    dispatch({
      type: TYPES.LOGOUT,
      payload: { initialState },
    });

    try {
      const res = await generateRequest.post({
        url: `${url}/users/logout`,
        invalidSession,
      });

      if (res.err) throw res;
    } catch (error) {
      console.log(error);
    }

    sessionStorage.clear();
  };

  const terminateSession = (
    sessionExpiredText = SESSION_MESSAGES.SESSION_EXPIRED_MESSAGE
  ) => {
    dispatch({
      type: TYPES.SESSION_EXPIRED,
      payload: {
        sessionExpired: true,
        sessionExpiredText,
      },
    });
  };

  const invalidSession = () => dispatch({ type: TYPES.INVALID_SESSION });

  const activeSession = (user) => {
    dispatch({
      type: TYPES.ACTIVE_SESSION,
      payload: user,
    });
  };

  const resetSendMailError = () => {
    dispatch({
      type: TYPES.RESET_ERROR,
      payload: {
        errorType: "SendMail",
        sendMailError: {
          err: false,
          errText: "",
          mailSended: false,
        },
      },
    });
  };

  const sendRecoveryMail = async (formToValidate, mail) => {
    const findInvalidField = (formToValidate) => {
      return formToValidate.find((el) => !el.valid);
    };

    const handleInvalidFieldError = () => {
      const err = {
        err: true,
        errText: isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data",
      };

      dispatch({ type: TYPES.SEND_MAIL_ERROR, payload: err });
    };

    const createRequestOptions = (mail) => {
      return {
        headers: {
          "content-type": "application/json",
        },
        body: { email: mail },
      };
    };

    const handleRequestError = (error) => {
      if (error.status === 404) {
        dispatch({
          type: TYPES.SEND_MAIL_ERROR,
          payload: {
            err: true,
            errText: isLangEs
              ? "El mail no corresponde a un usuario activo"
              : "Email address does not correspond to any active user",
          },
        });
      } else {
        dispatch({
          type: TYPES.SEND_MAIL_ERROR,
          payload: {
            err: true,
            errText: isLangEs
              ? "No pudimos enviar el mail"
              : "The email could not be sent",
          },
        });
      }
    };

    const invalid = findInvalidField(formToValidate);
    if (invalid) {
      handleInvalidFieldError();
      return;
    }

    dispatch({ type: TYPES.LOADING });

    try {
      const endpoint = `${url}/users/password/reset`;
      const options = createRequestOptions(mail);

      const data = await generateRequest.post({
        url: endpoint,
        options,
        invalidSession,
      });

      if (data.err) throw data.err;

      dispatch({ type: TYPES.SEND_MAIL, payload: true });
    } catch (error) {
      handleRequestError(error);
    }
  };

  const resetNewPassError = () => {
    dispatch({
      type: TYPES.RESET_ERROR,
      payload: {
        errorType: "ChangePass",
        newPassError: {
          err: false,
          errText: "",
        },
      },
    });
  };

  const changePass = async (formToValidate, datos) => {
    const findInvalidField = (formToValidate) => {
      return formToValidate.find((el) => !el.valid);
    };

    const handleInvalidFieldError = () => {
      const err = {
        err: true,
        errText: isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data",
      };

      dispatch({ type: TYPES.NEW_PASS_ERROR, payload: err });
    };

    const createRequestOptions = (datos) => {
      return {
        headers: {
          "content-type": "application/json",
        },
        body: datos,
      };
    };

    const handleRequestError = (error) => {
      dispatch({
        type: TYPES.NEW_PASS_ERROR,
        payload: {
          err: true,
          errText: isLangEs
            ? "No pudimos enviar el mail"
            : "The email could not be sent",
        },
      });
    };

    const invalid = findInvalidField(formToValidate);
    if (invalid) {
      handleInvalidFieldError();
      return;
    }

    dispatch({ type: TYPES.LOADING });

    try {
      const endpoint = `${url}/users/password/update`;
      const options = createRequestOptions(datos);

      const data = await generateRequest.patch({
        url: endpoint,
        options,
        invalidSession,
      });

      if (data.err) throw data.err;

      dispatch({ type: TYPES.CHANGE_PASS, payload: true });
    } catch (error) {
      handleRequestError(error);
    }
  };

  const setPassToken = (token) => {
    dispatch({
      type: TYPES.PASS_TOKEN,
      payload: token,
    });
  };

  const resetError = () => {
    dispatch({
      type: TYPES.RESET_ERROR,
      payload: {
        error: {
          err: false,
          errText: "",
        },
      },
    });
  };

  const setSignupError = (errText) => {
    dispatch({
      type: TYPES.SIGNUP_ERROR,
      payload: {
        err: true,
        errText,
      },
    });
  };

  const resetSignupError = () => {
    dispatch({
      type: TYPES.RESET_ERROR,
      payload: {
        errorType: "Signup",
        signupError: {
          err: false,
          errText: "",
        },
      },
    });
  };

  const signup = async (formToValidate, datos, isSandbox) => {
    const invalid = formToValidate.find((el) => !el.valid);

    if (invalid) {
      const err = {
        err: true,
        errText: isLangEs
          ? "Revisar los datos ingresados"
          : "Make sure you have entered valid data",
      };

      dispatch({
        type: TYPES.SIGNUP_ERROR,
        payload: err,
      });

      return;
    }

    dispatch({
      type: TYPES.LOADING,
    });

    try {
      const endpoint = `${url}/users/invite/register`;

      const body = {
        ...datos,
      };

      if (isSandbox) body.is_data_controller = true;

      const options = {
        headers: {
          "content-type": "application/json",
        },
        body,
      };

      const tokenData = await generateRequest.patch({
        url: endpoint,
        options,
        invalidSession,
      });

      if (tokenData.response_status === 400) throw tokenData;
      if (tokenData.err) throw tokenData.err;

      const token = tokenData.token;
      const userData = await generateRequest.get({
        url: `${url}/profile`,
        options: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        invalidSession,
      });

      dispatch({
        type: TYPES.GET_TOKEN,
        payload: {
          tokenData,
          userData,
          notifications: {
            notifications: [],
            not_seen: 0,
          },
        },
      });

      navigate(PRIVATE_ROUTES.DASHBOARD);
    } catch (error) {
      setSignupError(
        isLangEs
          ? "No es posible crear esta cuenta"
          : "It's not possible to create this account"
      );
    }
  };

  const updateUser = (data) => {
    let role;

    if (data?.dashboard_role_id)
      role =
        data.dashboard_role_id === 0
          ? ROLES.PROPIETARIO
          : data.dashboard_role_id === 1
          ? ROLES.ADMIN
          : ROLES.ANALISTA;
    else if (data?.user?.role) role = data.user.role;
    else role = data.role;

    const newUser = {
      ...data,
      role: role ? role : state.user.role,
    };

    const metadata = JSON.parse(sessionStorage.getItem("metadata"));
    saveMetadata({
      metadata: { ...newUser.metadata, filters: metadata.metadata.filters },
    });

    dispatch({
      type: TYPES.UPDATE_USER_DATA,
      payload: newUser,
    });
  };

  const resetUser = () => {
    dispatch({
      type: TYPES.LOGOUT,
      payload: {
        initialState,
        reset: true,
      },
    });
  };

  const data = useMemo(
    () => ({
      user,
      token,
      loading,
      error,
      sendMailError,
      sessionExpired,
      sessionExpiredText,
      mailSended,
      newPassError,
      passChanged,
      signupError,
      isFacekey,
      terminateSession,
      login,
      ssoLogin,
      ssoLoginError,
      activeSession,
      setSendMailError,
      logout,
      sendRecoveryMail,
      resetSendMailError,
      resetNewPassError,
      setNewPassError,
      setLogError,
      changePass,
      setPassToken,
      resetError,
      setSignupError,
      resetSignupError,
      signup,
      updateUser,
      keepSessionOpen,
      resetUser,
      getNotifications,
      invalidSession,
    }),
    [
      user,
      token,
      loading,
      error,
      sendMailError,
      sessionExpired,
      sessionExpiredText,
      mailSended,
      newPassError,
      passChanged,
      signupError,
      terminateSession,
      login,
      ssoLogin,
      ssoLoginError,
      activeSession,
      setSendMailError,
      logout,
      sendRecoveryMail,
      resetSendMailError,
      resetNewPassError,
      setNewPassError,
      setLogError,
      changePass,
      setPassToken,
      resetError,
      setSignupError,
      resetSignupError,
      signup,
      updateUser,
      keepSessionOpen,
      resetUser,
      getNotifications,
      invalidSession,
      isFacekey,
    ]
  );

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
