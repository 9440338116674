import { useContext, useState } from "react";
import TextInput from "./TextInput";
import {
  validateName,
  validatePass,
  validateRepeatPass,
} from "../helpers/formValidations";
import InputPass from "./InputPass";
import { useFormValidation } from "../hooks/useFormValidation";
import SmallLoader from "./SmallLoader";
import ErrorSlideTimeOut from "./ErrorSlideTimeOut";
import AuthContext from "../contexts/AuthContext";
import { useLocation /*useNavigate*/ } from "react-router-dom";
// import { PUBLIC_ROUTES } from "./pages/routes";
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "./Button";
import { useTranslate } from "@tolgee/react";

const initialState = (email, token = null) => {
  return {
    first_name: "",
    last_name: "",
    email,
    password1: "",
    password2: "",
    token,
  };
};

const initialValidateForm = [
  {
    name: "first_name",
    valid: true,
  },
  {
    name: "last_name",
    valid: true,
  },
  {
    name: "password1",
    valid: true,
  },
  {
    name: "password2",
    valid: true,
  },
];

const SignUp = () => {
  const location = useLocation();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { signupError, loading, signup, setSignupError, resetSignupError } =
    useContext(AuthContext);
  const { validateForm, setValidateForm, handleFormValidation } =
    useFormValidation(initialValidateForm);
  //const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [form, setForm] = useState(
    initialState(searchParams.get("email"), searchParams.get("token"))
  );
  const isSandbox =
    window.location.origin.includes("sandboxapp") ||
    window.location.origin.includes("devapp") ||
    window.location.origin.includes("localhost");

  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const first_name = form.first_name;
    const last_name = form.first_name;
    const email = form.email;
    const password1 = form.password1;
    const password2 = form.password2;

    if (!first_name || !last_name || !email || !password1 || !password2) {
      setSignupError("Debe completar todos los campos");
      return;
    }

    signup(validateForm, form, isSandbox);
    setValidateForm(initialValidateForm);
  };

  const styles = {
    boxShadow:
      "0px 6px 40px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.0274815)",
  };

  if (openModal) {
    return (
      <section className="modal">
        <div className="modal--center-container modal__signup">
          <h3 className="heading-1">{t("use_data_title")}</h3>
          <div className="text-2 modal__signup__text modal__signup__overflow">
            <PrivacyText />
            <DataUseText />
          </div>
          <div className="modal__signup__btn">
            <Button
              handleClick={() => setOpenModal(false)}
              styles={{ padding: "9px 12px" }}
              text={t("close_btn")}
            />
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="signIn__title signup__title">
        <h2 className="heading-1">Forma parte de nuestra red</h2>
        <h2 className="heading-1 signup--h2-mb">Empieza ahora</h2>
        <p className="text-1">Ingresa tus datos</p>
      </section>
      <form onSubmit={handleSubmit} className="signIn__form signup__form">
        <div className="signup__form--group">
          <TextInput
            form={form}
            inputName="first_name"
            inputLabel="Juan"
            description="Nombre"
            setValue={setForm}
            validate={validateName}
            validateErrorText="Sólo letras y espacios"
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ maxWidth: "none" }}
          />
          <TextInput
            form={form}
            inputName="last_name"
            inputLabel="Pérez"
            description="Apellido"
            setValue={setForm}
            validate={validateName}
            validateErrorText="Sólo letras y espacios"
            handleFormValidation={handleFormValidation}
            styles={styles}
            inputStylesMUI={{ maxWidth: "none" }}
          />
        </div>
        <TextInput
          form={form}
          inputName="email"
          description="Correo electrónico"
          disabled={true}
          validateErrorText="Debe ingresar un email válido"
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />
        <InputPass
          form={form}
          inputName="password1"
          description="Contraseña"
          setValue={setForm}
          validate={validatePass}
          infoText="Incluye al menos una letra mayúscula, una minúscula, un número y uno de los siguientes caracteres @$!#%*?&"
          validateErrorText="Debe ingresar una contraseña válida"
          iconStyles={{ width: "300px" }}
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />
        <InputPass
          form={form}
          inputName="password2"
          description="Repetir Contraseña"
          setValue={setForm}
          validate={validateRepeatPass}
          handleFormValidation={handleFormValidation}
          styles={styles}
          inputStylesMUI={{ maxWidth: "none" }}
        />

        {isSandbox ? <SandboxCheck /> : <ProdCheck />}

        {acceptTerms ? (
          <input
            type="submit"
            value="Crear cuenta"
            className="signIn__form--btn signup__form--btn"
          />
        ) : (
          <button
            disabled="disabled"
            className="signIn__form--btn--disable signup__form--btn--disable"
            onClick={signup}
          >
            Crear cuenta
          </button>
        )}
      </form>
      {loading && (
        <div className="signIn__submit signup--loader">
          <SmallLoader />
        </div>
      )}
      <ErrorSlideTimeOut
        error={signupError}
        restartFn={resetSignupError}
        styles={{ paddingLeft: 0 }}
      />
      {/* <div className="signIn__new">
        <span style={{ marginRight: "8px" }} className="text-2">
          ¿Ya tienes una cuenta?
        </span>
        <button
          onClick={() => navigate(PUBLIC_ROUTES.SIGNIN)}
          className="text-2 color--dodger-blue"
        >
          Inicia sesión
        </button>
      </div> */}
    </>
  );

  function ProdCheck() {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptTerms}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            onChange={() => setAcceptTerms(true)}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label={
          <>
            <span className="text-2">He leído los </span>
            <a
              href="https://www.trully.ai/aviso-de-privacidad-datos/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2 color--dodger-blue"
              style={{ textDecoration: "none" }}
            >
              términos de privacidad
            </a>
          </>
        }
      />
    );
  }

  function SandboxCheck() {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptTerms}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            onChange={() => setAcceptTerms(true)}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label={
          <>
            <span className="text-2">Acepto los </span>
            <button onClick={() => setOpenModal(true)}>
              <span className="text-2 color--dodger-blue">
                Términos y condiciones
              </span>
              <span className="text-2"> y el </span>
              <span className="text-2 color--dodger-blue"> Uso de Datos</span>
            </button>
          </>
        }
      />
    );
  }

  function PrivacyText() {
    return (
      <div className="text-2 modal__signup__text">
        <h4 className="heading-2">
          Aviso de privacidad para pruebas en Sandbox
        </h4>
        <p>
          Para consultar el aviso de privacidad completo, puedes acceder a
          nuestra página web en{" "}
          <a
            href="https://www.trully.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-2 color--dodger-blue"
            style={{ textDecoration: "none" }}
          >
            https://trully.ai
          </a>{" "}
        </p>
        <p>Fecha de actualización: noviembre de 2024.</p>
        <p>
          Truface, S.A.P.I. de C.V. (“Trully”), en cumplimiento con la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares, con domicilio en Boulevard Manuel Ávila Camacho número
          170, piso 7 oficina 703, Colonia Reforma Social, Ciudad de México,
          México, C.P. 11650, emite el siguiente aviso de privacidad para fines
          de pruebas en un entorno de sandbox.
        </p>
        <h4 className="heading-3">1. Datos personales recabados</h4>
        <p>
          Para efectos de pruebas en sandbox, Trully podrá tratar los siguientes
          datos personales:
        </p>
        <ul>
          <li>Nombre completo</li>
          <li>Datos de contacto (correo electrónico, teléfono)</li>
          <li>Imagen de identificación (selfie y documento)</li>
          <li>
            Información del dispositivo (IP, geolocalización, proveedor de
            internet)
          </li>
        </ul>
        <h4 className="heading-3">2. Finalidades del tratamiento</h4>
        <p>Durante las pruebas, los datos serán utilizados únicamente para:</p>
        <ul>
          <li>Verificación de identidad pre productiva</li>
          <li>Pruebas de funcionalidad en la prevención de fraude</li>
        </ul>
        <h4 className="heading-3">3. Uso de cookies y tecnologías similares</h4>
        <p>
          Para optimizar la experiencia en nuestro entorno de prueba, podríamos
          utilizar cookies y tecnologías similares para identificar a los
          usuarios y sus dispositivos. Estos mecanismos nos permiten simular
          condiciones de navegación y mejorar el rendimiento de la plataforma.
        </p>
        <h4 className="heading-3">4. Transferencias de datos</h4>
        <p>
          No se realizarán transferencias de datos personales en este entorno de
          sandbox. Trully no es responsable de decisiones de terceros basadas en
          pruebas realizadas en este ambiente.
        </p>
        <h4 className="heading-3">5. Derechos de los titulares</h4>
        <p>
          Para consultas sobre este aviso de privacidad en sandbox, puedes
          contactarnos en{" "}
          <a
            href="mailto:legales@trully.ai"
            className="text-2 color--dodger-blue"
            style={{ textDecoration: "none" }}
          >
            legales@trully.ai
          </a>{" "}
        </p>
        <h4 className="heading-3">6. Cambios al aviso de privacidad</h4>
        <p>
          Cualquier modificación a este aviso de privacidad en sandbox será
          publicada en nuestra página web:{" "}
          <a
            href="https://www.trully.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-2 color--dodger-blue"
            style={{ textDecoration: "none" }}
          >
            https://trully.ai
          </a>{" "}
        </p>
      </div>
    );
  }

  function DataUseText() {
    return (
      <div className="text-2 modal__signup__text">
        <h4 className="heading-2">Condiciones de Uso de Datos en Sandbox</h4>
        <h4 className="heading-3">1. Definiciones</h4>
        <ol>
          <li>
            Leyes de Protección de Datos: Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares
          </li>
          <li>
            Información: Cualquier información proporcionada por Truface,
            S.A.P.I. de C.V. (“Trully”) y obtenida en relación con el uso de la
            Aplicación.
          </li>
          <li>
            Datos Personales: Información sobre una persona física identificada
            o identificable, sujeta a protección bajo la LFPDPPP.
          </li>
          <li>
            Período de Prueba: Se define como el entorno en el cual se dispone
            de una cantidad limitada de peticiones para realizar las pruebas de
            integración, sin un período finito establecido.
          </li>
        </ol>
        <h4 className="heading-3">
          2. Condiciones de Uso del Entorno de Pruebas
        </h4>
        <p>
          Estas condiciones se establecen entre Trully, en su calidad de
          responsable de datos personales según la Ley de Protección de Datos,
          en México o como data controller bajo el Reglamento General de
          Protección de Datos (GDPR), y el cliente (la empresa o entidad que
          contrata los servicios de prueba). Estas condiciones aplican al uso de
          la plataforma de verificación de identidad y prevención de fraude de
          Trully (la “Aplicación”) en un entorno de pruebas (Sandbox), diseñado
          exclusivamente para evaluación y desarrollo (“Objetivo”).
        </p>
        <p>
          Al acceder al entorno de pruebas, usted acepta estas Condiciones de
          Uso. Si no está de acuerdo, debe suspender su acceso al entorno
          inmediatamente. Trully se reserva el derecho de revocar el acceso o la
          licencia en cualquier momento.
        </p>
        <h4 className="heading-3">3. Licencia</h4>
        <p>
          3.1 Trully concede al cliente una licencia no exclusiva,
          intransferible y temporal para utilizar la Aplicación durante el
          Período de Prueba, únicamente para fines de prueba y evaluación. La
          Aplicación se desactivará automáticamente al término del Período de
          Prueba.
        </p>
        <p>
          3.2 Durante el Período de Prueba, Trully podrá revocar esta licencia
          de inmediato si se incumplen las obligaciones aquí establecidas.
        </p>
        <p>
          3.3 El cliente se compromete a usar el entorno de pruebas solo para
          evaluación, y Trully se reserva el derecho de restablecer el entorno
          según sea necesario..
        </p>
        <p>
          3.4 El entorno de pruebas se ofrece “tal cual” y “según
          disponibilidad”. Trully no garantiza un funcionamiento ininterrumpido
          ni libre de errores. La responsabilidad de su uso recae completamente
          en el cliente.
        </p>
        <p>
          3.5 El cliente es responsable de la seguridad de sus contraseñas u
          otros elementos de acceso. Trully debe ser notificado de inmediato en
          caso de pérdida, robo, o uso indebido de cualquier elemento de acceso.
        </p>
        <p>
          3.6 El cliente es responsable de la veracidad de los datos ingresados
          en el entorno de pruebas y de la obtención de cualquier consentimiento
          pertinente de los titulares de los datos, en caso de ser necesario.
        </p>
        <h4 className="heading-3">4. Uso Prohibido</h4>
        <p>El cliente se compromete a no:</p>
        <ol type="a">
          <li>utilizar el entorno de pruebas con fines comerciales;</li>
          <li>compartir su acceso con terceros sin autorización;</li>
          <li>
            utilizar el entorno de pruebas en incumplimiento de cualquier ley
            aplicable;
          </li>
          <li>dañar o perjudicar a Trully o a terceros;</li>
          <li>
            monitorear el entorno con fines de competencia o benchmarking;
          </li>
          <li>introducir virus o códigos maliciosos en el entorno;</li>
          <li>
            aplicar ingeniería inversa, desensamblar, o intentar extraer el
            código fuente de la Aplicación
          </li>
        </ol>
        <h4 className="heading-3">5. No Divulgación</h4>
        <p>
          5.1 Como parte de esta prueba, Trully proporciona acceso a la
          Aplicación y a la Información bajo condiciones de confidencialidad. El
          cliente se compromete a mantener dicha confidencialidad durante un
          período de diez años desde la fecha de divulgación. Esto incluye no:
        </p>
        <ol type="a">
          <li>
            comunicar o divulgar la Información o la Aplicación a terceros sin
            autorización;
          </li>
          <li>compartir su acceso con terceros sin autorización;</li>
          <li>
            copiar o reproducir la Información o la Aplicación, salvo en lo
            estrictamente necesario para los fines de prueba.
          </li>
        </ol>
        <h4 className="heading-3">6. Derechos de Propiedad</h4>
        <p>
          6.1 Trully es la titular de todos los derechos de propiedad
          intelectual sobre la Aplicación y la Información relacionada. El
          acceso al entorno de pruebas no transfiere derechos de propiedad
          intelectual al cliente, y cualquier copia de la Aplicación deberá
          llevar una indicación de su propiedad por parte de Trully.
        </p>
        <h4 className="heading-3">7. Protección de Datos Personales</h4>
        <p>
          7.1 Trully actúa como responsable de los datos personales recabados
          durante la prueba. Al utilizar la Aplicación, ambas partes pueden
          tratar datos personales de sus respectivos representantes y
          colaboradores. Cada parte se compromete a cumplir con las Leyes de
          Protección de Datos aplicables y a notificar a sus representantes
          sobre el uso de sus datos en esta prueba.
        </p>
        <p>
          7.2 Trully implementará medidas de seguridad adecuadas para proteger
          los datos personales tratados en el entorno de pruebas y asegura que
          estos datos solo se utilizarán para fines de evaluación. El cliente se
          compromete a mantener la confidencialidad y a limitar el acceso a los
          datos personales según lo establecido en las Leyes de Protección de
          Datos.
        </p>
        <h4 className="heading-3">8. Limitación de Responsabilidad</h4>
        <p>
          8.1 El cliente indemnizará a Trully y a sus representantes frente a
          cualquier pérdida, daño o gasto (incluidos honorarios legales)
          derivado del incumplimiento de estas Condiciones de Uso o del uso
          indebido de la Aplicación.
        </p>
        <h4 className="heading-3">9. General</h4>
        <p>
          9.1 La persona que firma estas Condiciones en nombre del cliente
          confirma que tiene la autoridad para obligar a la empresa a cumplir
          con estos términos y condiciones.
        </p>
      </div>
    );
  }
};

export default SignUp;
