import { createContext, useEffect, useRef } from "react";

const ScrollContext = createContext(null);

const ScrollProvider = ({ children }) => {
  const containerRef = useRef(null);

  const scrollToChild = (childPosition) => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const { offsetTop } = container;

    const move = Math.abs(childPosition + offsetTop);
    if (!move) return;

    container?.scrollTo({
      top: move,
      behavior: "smooth",
    });
  };

  const handleScroll = (childPosition) => {
    setTimeout(() => scrollToChild(childPosition), 200);
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const currentRef = containerRef.current;
    currentRef.addEventListener("scroll", handleScroll);

    return () => {
      currentRef.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  const data = {
    containerRef,
    handleScroll,
  };

  return (
    <ScrollContext.Provider value={data}>{children}</ScrollContext.Provider>
  );
};

export default ScrollContext;
export { ScrollProvider };
